<template>
  <quick-quote-page
    id="System Messages"
    :optionsname="$options.name"
  >
    <template #content>
      <system-messages-table
        ref="maintenanceSystemMessagesTable"
      />
    </template>
  </quick-quote-page>
</template>

<script>
  import QuickQuotePage from '@/components/QuickQuotePage'
  import SystemMessagesTable from '@/components/maintenance/tables/SystemMessagesTable.vue'

  export default {
    name: 'SystemMessagesView',

    components: {
      QuickQuotePage,
      SystemMessagesTable,
    },
  }
</script>

<style scoped>

</style>
